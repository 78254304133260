export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_ERROR = 'SEARCH_ERROR';
export const SEARCH_AGGREGATIONS_REQUEST = 'SEARCH_AGGREGATIONS_REQUEST';
export const SEARCH_AGGREGATIONS_SUCCESS = 'SEARCH_AGGREGATIONS_SUCCESS';
export const SEARCH_AGGREGATIONS_ERROR = 'SEARCH_AGGREGATIONS_ERROR';
export const SEARCH_QUERY_UPDATE = 'SEARCH_QUERY_UPDATE';
export const SEARCH_QUERY_RESET = 'SEARCH_QUERY_RESET';
export const SEARCH_BASE_QUERIES_UPDATE = 'SEARCH_BASE_QUERIES_UPDATE';
export const RESET_SEARCH_RESULTS = 'RESET_SEARCH_RESULTS';

// TODO: rename below actions to prefix `SEARCH`
export const CHANGE_SEARCH_BOX_NAMESPACE = 'CHANGE_SEARCH_BOX_NAMESPACE';
export const NEW_SEARCH_REQUEST = 'NEW_SEARCH_REQUEST';

export const LITERATURE_REQUEST = 'LITERATURE_REQUEST';
export const LITERATURE_SUCCESS = 'LITERATURE_SUCCESS';
export const LITERATURE_ERROR = 'LITERATURE_ERROR';
export const LITERATURE_REFERENCES_REQUEST = 'LITERATURE_REFERENCES_REQUEST';
export const LITERATURE_REFERENCES_SUCCESS = 'LITERATURE_REFERENCES_SUCCESS';
export const LITERATURE_REFERENCES_ERROR = 'LITERATURE_REFERENCES_ERROR';
export const REFERENCES_DIFF_REQUEST = 'REFERENCES_DIFF_REQUEST';
export const REFERENCES_DIFF_SUCCESS = 'REFERENCES_DIFF_SUCCESS';
export const REFERENCES_DIFF_ERROR = 'REFERENCES_DIFF_ERROR';
export const LITERATURE_AUTHORS_REQUEST = 'LITERATURE_AUTHORS_REQUEST';
export const LITERATURE_ALL_AUTHORS_REQUEST = 'LITERATURE_ALL_AUTHORS_REQUEST';
export const LITERATURE_AUTHORS_SUCCESS = 'LITERATURE_AUTHORS_SUCCESS';
export const LITERATURE_ALL_AUTHORS_SUCCESS = 'LITERATURE_ALL_AUTHORS_SUCCESS';
export const LITERATURE_AUTHORS_ERROR = 'LITERATURE_AUTHORS_ERROR';
export const LITERATURE_ALL_AUTHORS_ERROR = 'LITERATURE_ALL_AUTHORS_ERROR';

export const EXCEPTIONS_REQUEST = 'EXCEPTIONS_REQUEST';
export const EXCEPTIONS_SUCCESS = 'EXCEPTIONS_SUCCESS';
export const EXCEPTIONS_ERROR = 'EXCEPTIONS_ERROR';

export const INSPECT_REQUEST = 'INSPECT_REQUEST';
export const INSPECT_SUCCESS = 'INSPECT_SUCCESS';
export const INSPECT_ERROR = 'INSPECT_ERROR';

export const CITATIONS_SUMMARY_REQUEST = 'CITATIONS_SUMMARY_REQUEST';
export const CITATIONS_SUMMARY_SUCCESS = 'CITATIONS_SUMMARY_SUCCESS';
export const CITATIONS_SUMMARY_ERROR = 'CITATIONS_SUMMARY_ERROR';

export const CITATIONS_BY_YEAR_REQUEST = 'CITATIONS_BY_YEAR_REQUEST';
export const CITATIONS_BY_YEAR_SUCCESS = 'CITATIONS_BY_YEAR_SUCCESS';
export const CITATIONS_BY_YEAR_ERROR = 'CITATIONS_BY_YEAR_ERROR';

export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_SET_PREFERENCE = 'USER_SET_PREFERENCE';
export const LOGGED_IN_USER_REQUEST = 'LOGGED_IN_USER_REQUEST';
export const USER_SIGN_UP_REQUEST = 'USER_SIGN_UP_REQUEST';
export const USER_SIGN_UP_ERROR = 'USER_SIGN_UP_ERROR';
export const USER_SIGN_UP_SUCCESS = 'USER_SIGN_UP_SUCCESS';
export const USER_SET_ORCID_PUSH_SETTING_REQUEST =
  'USER_SET_ORCID_PUSH_SETTING_REQUEST';
export const USER_SET_ORCID_PUSH_SETTING_SUCCESS =
  'USER_SET_ORCID_PUSH_SETTING_SUCCESS';
export const USER_SET_ORCID_PUSH_SETTING_ERROR =
  'USER_SET_ORCID_PUSH_SETTING_ERROR';

export const SETTINGS_CHANGE_EMAIL_REQUEST = 'SETTINGS_CHANGE_EMAIL_REQUEST';
export const SETTINGS_CHANGE_EMAIL_ERROR = 'SETTINGS_CHANGE_EMAIL_ERROR';
export const SETTINGS_CHANGE_EMAIL_SUCCESS = 'SETTINGS_CHANGE_EMAIL_SUCCESS';

export const SUBMIT_REQUEST = 'SUBMIT_REQUEST';
export const SUBMIT_ERROR = 'SUBMIT_ERROR';
export const SUBMIT_SUCCESS = 'SUBMIT_SUCCESS';
export const INITIAL_FORM_DATA_REQUEST = 'INITIAL_FORM_DATA_REQUEST';
export const INITIAL_FORM_DATA_SUCCESS = 'INITIAL_FORM_DATA_SUCCESS';
export const INITIAL_FORM_DATA_ERROR = 'INITIAL_FORM_DATA_ERROR';

export const AUTHOR_REQUEST = 'AUTHOR_REQUEST';
export const AUTHOR_SUCCESS = 'AUTHOR_SUCCESS';
export const AUTHOR_ERROR = 'AUTHOR_ERROR';
export const AUTHOR_PUBLICATION_SELECTION_SET = 'AUTHOR_PUBLICATION_SELECTION';
export const AUTHOR_PUBLICATION_SELECTION_CLEAR =
  'AUTHOR_PUBLICATION_SELECTION_CLEAR';
export const AUTHOR_SET_ASSIGN_DRAWER_VISIBILITY =
  'AUTHOR_SET_ASSIGN_DRAWER_VISIBILITY';
export const AUTHOR_PUBLICATION_CLAIM_SELECTION =
  'AUTHOR_PUBLICATION_CLAIM_SELECTION';
export const AUTHOR_PUBLICATIONS_CLAIM_CLEAR =
  'AUTHOR_PUBLICATIONS_CLAIM_CLEAR';
export const AUTHOR_PUBLICATION_UNCLAIM_SELECTION =
  'AUTHOR_PUBLICATION_UNCLAIM_SELECTION';
export const AUTHOR_PUBLICATIONS_UNCLAIM_CLEAR =
  'AUTHOR_PUBLICATIONS_UNCLAIM_CLEAR';

export const JOB_REQUEST = 'JOB_REQUEST';
export const JOB_SUCCESS = 'JOB_SUCCESS';
export const JOB_ERROR = 'JOB_ERROR';

export const CONFERENCE_REQUEST = 'CONFERENCE_REQUEST';
export const CONFERENCE_SUCCESS = 'CONFERENCE_SUCCESS';
export const CONFERENCE_ERROR = 'CONFERENCE_ERROR';

export const INSTITUTION_REQUEST = 'INSTITUTION_REQUEST';
export const INSTITUTION_SUCCESS = 'INSTITUTION_SUCCESS';
export const INSTITUTION_ERROR = 'INSTITUTION_ERROR';

export const SEMINAR_REQUEST = 'SEMINAR_REQUEST';
export const SEMINAR_SUCCESS = 'SEMINAR_SUCCESS';
export const SEMINAR_ERROR = 'SEMINAR_ERROR';

export const EXPERIMENT_REQUEST = 'EXPERIMENT_REQUEST';
export const EXPERIMENT_SUCCESS = 'EXPERIMENT_SUCCESS';
export const EXPERIMENT_ERROR = 'EXPERIMENT_ERROR';

export const JOURNAL_REQUEST = 'JOURNAL_REQUEST';
export const JOURNAL_SUCCESS = 'JOURNAL_SUCCESS';
export const JOURNAL_ERROR = 'JOURNAL_ERROR';

export const DATA_REQUEST = 'DATA_REQUEST';
export const DATA_SUCCESS = 'DATA_SUCCESS';
export const DATA_ERROR = 'DATA_ERROR';

export const UI_CLOSE_BANNER = 'UI_CLOSE_BANNER';
export const UI_CHANGE_GUIDE_MODAL_VISIBILITY =
  'UI_CHANGE_GUIDE_MODAL_VISIBILITY';
export const UI_SCROLL_VIEWPORT_TO_PREVIOUS_REFERENCE =
  'UI_SCROLL_VIEWPORT_TO_PREVIOUS_REFERENCE';

export const CLEAR_STATE = 'CLEAR_STATE';

export const BIBLIOGRAPHY_GENERATOR_REQUEST = 'BIBLIOGRAPHY_GENERATOR_REQUEST';
export const BIBLIOGRAPHY_GENERATOR_SUCCESS = 'BIBLIOGRAPHY_GENERATOR_SUCCESS';
export const BIBLIOGRAPHY_GENERATOR_ERROR = 'BIBLIOGRAPHY_GENERATOR_ERROR';

export const LITERATURE_SELECTION_SET = 'LITERATURE_SELECTION_SET';
export const LITERATURE_SELECTION_CLEAR = 'LITERATURE_SELECTION_CLEAR';
export const LITERATURE_SET_ASSIGN_DRAWER_VISIBILITY =
  'LITERATURE_SET_ASSIGN_DRAWER_VISIBILITY';
export const LITERATURE_SET_ASSIGN_LITERATURE_ITEM_DRAWER_VISIBILITY =
  'LITERATURE_SET_ASSIGN_LITERATURE_ITEM_DRAWER_VISIBILITY';
export const LITERATURE_SET_CURATE_DRAWER_VISIBILITY =
  'LITERATURE_SET_CURATE_DRAWER_VISIBILITY';

export const BACKOFFICE_LOGIN_REQUEST = 'BACKOFFICE_LOGIN_REQUEST';
export const BACKOFFICE_LOGIN_CHECK = 'BACKOFFICE_LOGIN_CHECK';
export const BACKOFFICE_LOGIN_ERROR = 'BACKOFFICE_LOGIN_ERROR';
export const BACKOFFICE_LOGIN_SUCCESS = 'BACKOFFICE_LOGIN_SUCCESS';
export const BACKOFFICE_LOGOUT_SUCCESS = 'BACKOFFICE_LOGOUT_SUCCESS';
export const BACKOFFICE_SEARCH_REQUEST = 'BACKOFFICE_SEARCH_REQUEST';
export const BACKOFFICE_SEARCH_ERROR = 'BACKOFFICE_SEARCH_ERROR';
export const BACKOFFICE_SEARCH_SUCCESS = 'BACKOFFICE_SEARCH_SUCCESS';
export const BACKOFFICE_SEARCH_QUERY_UPDATE = 'BACKOFFICE_SEARCH_QUERY_UPDATE';
export const BACKOFFICE_SEARCH_QUERY_RESET = 'BACKOFFICE_SEARCH_QUERY_RESET';
export const BACKOFFICE_AUTHOR_REQUEST = 'BACKOFFICE_AUTHOR_REQUEST';
export const BACKOFFICE_AUTHOR_ERROR = 'BACKOFFICE_AUTHOR_ERROR';
export const BACKOFFICE_AUTHOR_SUCCESS = 'BACKOFFICE_AUTHOR_SUCCESS';
export const BACKOFFICE_RESOLVE_ACTION_REQUEST =
  'BACKOFFICE_RESOLVE_ACTION_REQUEST';
export const BACKOFFICE_RESOLVE_ACTION_SUCCESS =
  'BACKOFFICE_RESOLVE_ACTION_SUCCESS';
export const BACKOFFICE_RESOLVE_ACTION_ERROR =
  'BACKOFFICE_RESOLVE_ACTION_ERROR';
export const BACKOFFICE_DELETE_REQUEST = 'BACKOFFICE_DELETE_REQUEST';
export const BACKOFFICE_DELETE_SUCCESS = 'BACKOFFICE_DELETE_SUCCESS';
export const BACKOFFICE_DELETE_ERROR = 'BACKOFFICE_DELETE_ERROR';
